
import { retrieveButter,
         getDictFLJ,
         getDictionary,
         getStateRules,
         getElections,
         getUpcomingElections,
         getUpcomingElectionsFiltered,
         getLastElection,
         getDeadlines,
         getChosenState,
         getNewVfaDate } from '~/utils/butterUtils'
import { MS_PER_DAY,
         getVFAParameters } from '~/utils/VFAParameters'

export default {
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/elections/_state/index.vue asyncData ')
  },
  data () {
    return {
      isOpenElectionsInfo: false,
      isOpenResourceLinks: false,
      electionsIcon: 'fas fa-caret-right',
      UXViewC: false,
      isLoading: true,
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    today () {
      return getNewVfaDate().getTime()
    },
    dateFormat () {
      return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics[this.chosenState.toLowerCase()] : null
    },
    chosenState () {
      return getChosenState(this.$store, this.$route)
    },
    whatKindOfState () {
      const cs = this.chosenState
      if (cs==="AS" || cs==="GU" || cs==="MP" || cs==="PR" || cs==="VI") {
        return "territory"
      } else if (cs==="DC") {
        return "district"
      } else {
        return "state"
      }
    },
    ballotpediaURL () {
      if (!this.stateRules.name) {
        return "https://ballotpedia.org/"
      }

      let year = getNewVfaDate().getFullYear()
      let st = this.stateRules.name + "_elections,_" + year
      /*
        exception to the pattern for states
          DC  https://ballotpedia.org/Municipal_elections_in_Washington,_D.C._(2022)
      */
      if (this.stateRules.iso==="DC") {
        st = "Municipal_elections_in_Washington,_D.C._(" + year + ")"
      }
      return "https://ballotpedia.org/" + st.replace(/ /g, "_")
    },
    stateRules () {
      let scr0 = this.butterStateVotingRules
      if (scr0) {
        let scr1 = scr0.find(x => x.stateid.toLowerCase().slice(0, 2) === this.$route.params.state.toLowerCase())
        return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
      } else {
        return {'iso': this.$route.params.state}
      }
    },
    elections () {
      const scr0 = this.butterStateElections
      if (scr0) {
        const scr1 = scr0.filter(item => item.stateid.toLowerCase().slice(0, 2) === this.chosenState.toLowerCase())
        return getElections(scr1, this.FWAB_TRIGGER_DEFAULT, this.lang)
      } else {
        return []
      }
    },
    upcomingElections () {
      /** 2023-11-20 John Yee
       *  per conversation with Heidi
       *  all elections should be visible to any voter
       *  i.e. no filtering based on military or registration status
       *
       *  but, if she changes her mind, then here is where we filter the elections ...
       *    return getUpcomingElectionsFiltered (this.elections, stateRules.militaryseparate, stateRules.votertypesused, stateRules.usesvoterisregistered, this.voterType, this.voterIsRegistered)
       * 
       *  note: militaryseparate to be removed eventually
       */
      return getUpcomingElections (this.elections)
    },
    deadlineElection () {
      // normally you use ...
      //    return getDeadlineElection (this.upcomingElections, this.voterIsRegistered)
      // but getLastElection is a special case for this page
      return getLastElection (this.upcomingElections) 
    },

    deadlines () {
      let d = getDeadlines (this.deadlineElection, this.voterType, this.voterIsRegistered)
      return d
    },
    daysToElection () {
      return this.deadlines.deadlineElection.daysToElection
    },
    daysToRegistrationDeadLine () {
      let d1 = 0
      let d2 = 0
      let dd = -9999
      if (this.deadlines.registration) {
        for (let ii=0; ii<this.deadlines.registration.length; ii++){
          d1 = this.deadlines.registration[ii].date
          d2 = (new Date(d1).getTime()-this.today)/MS_PER_DAY
          dd = Math.max(dd, d2)
        }
      }
      return dd
    },
    daysToBallotRequestDeadLine () {
      let d1 = 0
      let d2 = 0
      let dd = -9999
      if (this.deadlines.ballotrequest) {
        for (let ii=0; ii<this.deadlines.ballotrequest.length; ii++){
          d1 = this.deadlines.ballotrequest[ii].date
          d2 = (new Date(d1).getTime()-this.today)/MS_PER_DAY
          dd = Math.max(dd, d2)
        }
      }
      return dd
    },
    daysToBallotReturnDeadline () {
      let d1 = 0
      let d2 = 0
      let dd = -9999
      if (this.deadlines.ballotreturn) {
        for (let ii=0; ii<this.deadlines.ballotreturn.length; ii++){
          d1 = this.deadlines.ballotreturn[ii].date
          d2 = (new Date(d1).getTime()-this.today)/MS_PER_DAY
          dd = Math.max(dd, d2)
        }
      }
      return dd
    },
    FWABDeadlineDate () {
      return this.deadlines.FWAB.date
    },
    daysToFWABDeadline () {
      return this.deadlines.FWAB.daysToFWAB
    },
    voterType () {
      return this.$store.state.voterType
    },
    voterRegistration () {
      return this.$store.state.voterIsRegistered
    },
    voterTypeText () {
      return ({
        "all-voters":this.dict.I46,
        "civilian":this.dict.C18,
        "civilian-intend-to-return":this.dict.Q08,
        "civilian-uncertain-return":this.dict.Q09,
        "civilian-never-resided-in-us":this.dict.Q10,
        "military":this.dict.Q07,
        "military-active-duty":this.dict.C19,
        "military-spouse":this.dict.C20
      })[this.voterType]
    },
    voterIsRegistered () {
      return this.$store.state.voterIsRegistered
    },
    saveVoterRegistration (voterIsRegistered) {
      this.$store.commit('saveVoterIsRegistered', voterIsRegistered)
    },
    showUXA () {
      // plenty of time to register
      return (this.daysToElection>42) || 
             (this.daysToElection>0 &&
              this.daysToElection<=42 &&
              this.daysToRegistrationDeadLine>=0 &&
              this.daysToFWABDeadline>0
             )
    },
    showUXB () {
      // still time to register; but, deadlines approaching fast
      /*
      return this.daysToElection>0
          && this.daysToElection<=42
          && this.daysToRegistrationDeadline>=0
          && this.daysToFWABDeadline<0
      */
     /*
      return this.daysToElection>0 && this.daysToElection<=42 &&
             this.daysToRegistrationDeadLine>=0 && this.daysToFWABDeadline<0
      */
      return this.daysToElection>0 &&
             this.daysToElection<=42 &&
             this.daysToRegistrationDeadLine>=0 &&
             this.daysToFWABDeadline<0
    },
    showUXC () {
      // registration day passed
      // if voter is notregistered (unsure === notregistered), then cannot vote
      // however, if registered, then can vote
      // UXC is in the Hallway; hence, we include this.showUXHallway
      return this.showUXHallway &&
             this.voterIsRegistered!=='registered'
    },
    showUXHallway () {
      // registration day passed
      return this.daysToElection>0 &&
             this.daysToElection<=42 &&
             this.daysToRegistrationDeadLine<0
    },
    showNoUpcomingElections () {
      return !this.deadlineElection.date
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    FWAB_TRIGGER_DEFAULT () {
      return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    }
  },
  mounted () {
    this.isLoading = false
  },
  methods: {
    anonymousStart () {
      this.$buefy.toast.open({
        message: this.dict.D00,
        type: 'is-success',
        duration: 8000
      })
      if (this.daysToFWABDeadline>0) {
        this.$store.commit('saveVoterFWAB', true)
      } else {
        this.$store.commit('saveVoterFWAB', false)
      }

      let currentSelectedState = this.chosenState
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.dispatch('userdata/clearVoterData')
      this.$store.commit('saveSelectedState', currentSelectedState)
      this.$router.push(this.localePath({ name: 'request-stage', params :{ stage: 'start-fpca' } }))
    },
    anonymousRegisteredAndStart () {
      this.$store.commit('saveVoterIsRegistered', 'registered')
      this.anonymousStart()
      /**
       * 2022-10-15
       * test that this revision works
       */
      /*
      this.$buefy.toast.open({
        message: this.dict.D00,
        type: 'is-success',
        duration: 8000
      })
      if (this.daysToFWABDeadline>0) {
        this.$store.commit('saveVoterFWAB', true)
      } else {
        this.$store.commit('saveVoterFWAB', false)
      }

      let currentSelectedState = this.chosenState
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.dispatch('userdata/clearVoterData')
      this.$store.commit('saveSelectedState', currentSelectedState)
      this.$router.push(this.localePath({ name: 'request-stage', params :{ stage: 'start-fpca' } }))
      */
    },
    checkRegistration () {
      // this method is invoked when there is a "time-crunch" for the voter to get a ballot
      this.$store.commit('saveVoterTimeCrunch', 'yes')
      this.$store.commit('saveVoterIsRegistered', 'unsure')
      this.$router.push(this.localePath({ name: 'voter-stage', params :{ stage: 'voter-registered' } }))
    },
    gotoUXViewC () {
      this.$store.commit('saveVoterIsRegistered', 'notregistered')
      this.UXViewC=true
    },
    localizeIfAvailable (str) {
      if (typeof str !== 'string') {
        return str
      }
      let newStr = str
      if (str.includes('*')) {
        newStr = str.replace(/\*/g, '')
      }

      let t  = this.getDictFLJ(`election.${newStr.toLowerCase().replace(/\s/gi, '')}`, this.dict)
      return t.includes("Error: Cannot resolve") ? newStr : t
    },
    camelize (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase()
      })
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    signupEmail () {
      // to do
    }
  }
}
