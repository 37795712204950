import { render, staticRenderFns } from "./index.vue?vue&type=template&id=43186419&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=43186419&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43186419",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VfaScrollUp: require('/opt/build/repo/components/VfaScrollUp.vue').default,At: require('/opt/build/repo/components/At.js').default,ADateRenderer: require('/opt/build/repo/components/ADateRenderer.vue').default,VoterResourceButtons: require('/opt/build/repo/components/VoterResourceButtons.vue').default,ChatBot: require('/opt/build/repo/components/ChatBot.vue').default})
